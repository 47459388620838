.container {
  display: flex;
  width: 100%;
  min-width: 375px;
  min-height: 736px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 80px; /* 为底部输入框预留空间 */
}

.empty-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

}

.empty-chat-img {
  max-width: 100px;
  margin-bottom: 20px;
}

.empty-chat-text {
  font-size: 1.2em;
  color: #666;
}

.chat-content {
  display: flex;
  background: #f4f4f4;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 150px); /* 根据需要调整高度 */
  overflow-y: auto;
  padding-bottom: 10px; /* 防止内容紧贴输入框 */
}

.chat-messages {
  overflow-y: auto;
}

.chat-box {
  padding: 10px;
  overflow-y: auto;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  min-width: 10%;
}

.avatar {
  display: flex;
  flex-direction: row;
}

.avatar img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
}

.avatar-title {
  font-size: 0.8em;
  color: #666;
  margin-left: 10px;
}

.message-text {
  margin-left: 3ch;
  margin-top: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  text-align: left;
}
.message-image {
  max-width: 600px;
  max-height: 600px;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 0; /* 确保图片靠左 */
  display: flex;
  justify-content: flex-start; /* 靠左对齐 */
}


.message-loading {
  margin-left: 30px;
  margin-top: 10px;
  text-align: left;
  width: 60px;
}

.input-form {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f8f8f8;
  border-radius: 30px;
  border: 2px solid #007bff;
  position: fixed; /* 固定在底部 */
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 60px); /* 为两边留出空间 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* 确保输入框位于最上层 */
}

.input-with-upload {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
  /* border-radius: 30px; */
  overflow: hidden;
}

.upload-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  /* border-radius: 30px 0 0 30px; */
}

.upload-icon {
  width: 20px;
  height: 20px;
}

.file-input {
  position: absolute;
  left: -9999px;
}

.input-box {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 0 30px 30px 0;
  resize: none;
  height: auto;
  max-height: 200px; /* 最大高度限制 */
  overflow-y: auto; /* 超出最大高度时出现滚动条 */
  box-shadow: none;
  background-color: transparent; /* 背景颜色设为透明 */
}



.image-preview {
  position: relative;
  max-width: 80px;
  max-height: 80px;
  margin-left: 10px;
  border-radius: 8px;
  background-color: #333;
  border: 1px solid #555;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  margin-top: 15px; /* 预留顶部空间 */

}

.preview-img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
  display: block;

}

.remove-image-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.send-button {
  cursor: pointer;
  margin-left: 10px;
  width: 44px;
  height: 44px;
  margin-right: 15px;
}


