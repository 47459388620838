/* 容器样式 */
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 20px;
  background-color: #f4f4f9;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
}

.panel {
  min-width: 320px;
  max-width: 100%; /* 确保在小屏幕上不会超出可视区域 */
  margin: 0 auto; /* 居中对齐 */
  padding: 20px; /* 内边距 */
  background-color: #fff; /* 背景色 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  border-radius: 8px; /* 圆角边框 */
}


h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.segment-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.segment {
  flex: 1;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  color: gray;
  border-bottom: 2px solid transparent;
  transition: all 0.3s;
}

.segment.active {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.segment:hover {
  background-color: #e9e9e9;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}

.input-account,
.code-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.input-account input,
.code-container input {
  flex: 1;
  padding: 12px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

button {
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loginButton {
  margin-top: 20px;
  width: 100%;
}

/* 媒体查询，针对小屏幕优化 */
@media (max-width: 600px) {
  .container {
    padding: 10px;
  }
  .panel {
    max-width: 100%;
    padding: 15px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  input,
  button,
  .segment {
    padding: 10px;
    font-size: 14px;
  }
}
