.App {
  display: flex;
  text-align: center;
  background-color: #282c34;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

